import { toast, ToastOptions, TypeOptions } from "react-toastify";

/**
 * Displays a toast notification.
 *
 * @param title - The title of the notification.
 * @param message - The body message of the notification.
 * @param type - The type of notification: 'success', 'info', 'warning', or 'error'.
 * @param options - Additional toast options for customization.
 */
export const showNotification = (
  title: string,
  message: string,
  type: TypeOptions = "info",
  options?: ToastOptions
): void => {
  const content: React.ReactNode = (
    <div>
      <strong>{title}</strong>
      <p>{message}</p>
    </div>
  );

  const defaultOptions: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    ...options, // Allow overriding default options
  };

  switch (type) {
    case "success":
      toast.success(content, defaultOptions);
      break;
    case "info":
      toast.info(content, defaultOptions);
      break;
    case "warning":
      toast.warn(content, defaultOptions);
      break;
    case "error":
      toast.error(content, defaultOptions);
      break;
    default:
      toast(content, defaultOptions);
  }
};
