import { createOrUpdateItem, fetchItem } from "../api/generic";
import { UserData } from "../models/UserData";

export enum FavoriteType {
    RECIPES = 'recipes',
    INVENTORY = 'inventory',
}

const favorites = { inventory: [], recipes: [] }

export const addFavoriteToUser = async (type: FavoriteType, id: string, userId: string) => {
    
    const user = await fetchItem<UserData>('users', userId);
    if(!user) return;

    user.favorites = { ...(user?.favorites ?? favorites), [type]: [...(user?.favorites ?? favorites)[type], id] };
    
    await createOrUpdateItem('users', {...user, id: userId });
};

export const removeFavoriteFromUser = async (type: FavoriteType, id: string, userId: string) => {
    const user = await fetchItem<UserData>('users', userId);
    if(!user) return;

    user.favorites = { ...(user?.favorites ?? favorites), [type]: (user?.favorites ?? favorites)[type].filter(fav => fav !== id) };
    
    await createOrUpdateItem('users', {...user, id: userId });
}