import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  FormField,
  SpaceBetween,
  Select,
  Textarea,
} from "@cloudscape-design/components";
import {
  createItemWithId,
  fetchItems,
  subscribeToChanges,
} from "../api/generic";
import { UserData } from "../models/UserData";
import { Token } from "../config/tokens";

type Notification = {
  id?: string;
  userId: string;
  title: string;
  body: string;
  tokens: string[];
  url?: string;
};

const NotificationManager: React.FC = () => {
  const [secretKey, setSecretKey] = useState<string>(
    "c68762b6-d465-459f-bd11-ec7cf02cc64c"
  );

  const [tokens, setTokens] = useState<Token[]>([]);
  const [userOptions, setUserOptions] =
    useState<{ label: string; value: string }[]>();
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [notification, setNotification] = useState<Notification>();
  const [pending, setPending] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const unsubcribe = subscribeToChanges<Notification>(
      "pendingNotifications",
      setPending,
      () => {}
    );
    return () => unsubcribe();
  }, []);

  useEffect(() => {
    setNotification(undefined);
  }, [selectedUser, title, body]);

  // Add a notification to Firestore
  const handleAddNotification = async () => {
    if (!secretKey) {
      alert("Please enter a secret key.");
      return;
    }

    if (!selectedUser) {
      alert("Please select a user.");
      return;
    }

    if (!title) {
      alert("Please enter a title.");
      return;
    }

    if (!body) {
      alert("Please enter a body.");
      return;
    }

    // for each token in the user's tokens, add a notification
    const userTokens = tokens.find(
      (token) => token.id === selectedUser
    )?.tokens;

    if (!userTokens) {
      alert("No tokens found for the selected user.");
      return;
    }

    setNotification({
      userId: selectedUser,
      title,
      body,
      tokens: userTokens,
      url: "https://admin.horriblemeadery.com/batches",
    });
  };

  const createUniqueId = (userId: string, batch: string, step?: number) => {
    return `${userId}-${batch}${step ? "-" + step : ""}`;
  };

  const handleSendNotification = async () => {
    if (!notification) {
      alert("No notifications to send.");
      return;
    }

    try {
      if (notification)
        createItemWithId(
          "pendingNotifications",
          createUniqueId(notification.userId, "NA"),
          notification
        );
      console.log(notification);
      setNotification(undefined);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const handleTriggerNotifications = async () => {
    if (!secretKey) {
      alert("Please enter a secret key.");
      return;
    }

    try {
      const response = await fetch(
        "https://sendnotificationsworkerfunction-ogbfqlrosa-ew.a.run.app",
        //"http://127.0.0.1:5001/producery-7c030/europe-west1/sendNotificationsWorkerFunction",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${secretKey}`,
          },
        }
      );

      console.log("Response:", response);
    } catch (error) {
      console.error("Error triggering notification:", error);
    }
  };

  const handleCreateNotification = async () => {
    setLoading(true);
    try {
      const result = await (
        await fetch(
          "https://createnotificationsworkerfunction-ogbfqlrosa-ew.a.run.app",
          //"http://127.0.0.1:5001/producery-7c030/europe-west1/createNotificationsWorkerFunction",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${secretKey}`,
            },
          }
        )
      ).json();
      alert(result.message);
    } catch (error) {
      alert("Error creating notification: " + error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchUsersAndTokens = async () => {
      const u = await fetchItems("users");
      const t = await fetchItems("tokens");

      setTokens(t as Token[]);
      setUserOptions(
        (u as UserData[]).map((user) => ({
          label: `${user.name} (${user.email})`,
          value: user.uid,
        }))
      );
    };
    fetchUsersAndTokens();
  }, []);

  return (
    <SpaceBetween size="l">
      <FormField
        label="Secret Key"
        description="Enter the secret key to authenticate requests."
      >
        <Input
          value={secretKey}
          onChange={(e) => setSecretKey(e.detail.value)}
          placeholder="Enter secret key"
          autoComplete="off"
        />
      </FormField>

      <FormField
        label="User ID"
        description="The ID of the user to send the notification to."
      >
        <Select
          selectedOption={
            userOptions?.find((option) => option.value === selectedUser) ?? null
          }
          options={userOptions}
          onChange={(e) =>
            setSelectedUser(e.detail.selectedOption?.value ?? "")
          }
          placeholder="Enter User ID"
        />
      </FormField>

      <FormField label="Title" description="The title of the notification.">
        <Input
          value={title}
          onChange={(e) => setTitle(e.detail.value)}
          placeholder="Enter Notification Title"
        />
      </FormField>

      <FormField label="Body" description="The body of the notification.">
        <Textarea
          value={body}
          onChange={(e) => setBody(e.detail.value)}
          placeholder="Enter Notification Body"
        />
      </FormField>

      <FormField
        label="FCM Token"
        description="The FCM token of the user's device."
      >
        <Textarea
          value={
            selectedUser === ""
              ? ""
              : (tokens
                  .find((token) => token.id === selectedUser)
                  ?.tokens.join("\n") ??
                "NO TOKENS FOUND! User may not have logged in yet. This is normal. (for slackers). Tokens are stored in Producery and was added in version 1.9.0.18")
          }
          readOnly
          invalid={
            (tokens
              .find((token) => token.id === selectedUser)
              ?.tokens.join("\n") ?? "") === "" && selectedUser !== ""
          }
        />
      </FormField>

      <Button onClick={handleAddNotification} variant="normal">
        Add Notification
      </Button>

      <SpaceBetween size="l" direction="horizontal">
        <div>
          <h3>Notifications</h3>
          <SpaceBetween size="s">
            <Button
              onClick={handleSendNotification}
              variant="primary"
              disabled={!notification}
            >
              Send notifications
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              onClick={handleCreateNotification}
              variant="primary"
            >
              Create notifications
            </Button>
          </SpaceBetween>
          {notification ? (
            <ul>
              <li>
                <strong>{notification.title}</strong>
              </li>
              <li>{notification.body}</li>
            </ul>
          ) : (
            <p>No notifications added yet.</p>
          )}
        </div>
        <div>
          <h3>Pending Notifications</h3>
          <Button onClick={handleTriggerNotifications} variant="primary">
            Trigger notifications
          </Button>
          {pending.length > 0 ? (
            <ul>
              {pending.map((notification, index) => (
                <li key={index}>
                  <strong>{notification.title}</strong>
                </li>
              ))}
            </ul>
          ) : (
            <p>No pending notifications.</p>
          )}
        </div>
      </SpaceBetween>
    </SpaceBetween>
  );
};

export default NotificationManager;
