import { FC, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  ContentLayout,
  Header,
  Table,
} from "@cloudscape-design/components";

import { deleteItem as deleteInv } from "../api/generic";
import ErrorBar from "../components/ErrorBar";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import InventoryItem from "../models/InventoryItem";
import { prefixCollection } from "../utils/prefix";
import { sortName } from "../utils/sort";
import {
  addFavoriteToUser,
  FavoriteType,
  removeFavoriteFromUser,
} from "../utils/favorite";

const Inventory: FC = () => {
  const { userObject, tenant } = useAuthContext();
  const navigate = useNavigate();
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [error, setError] = useState<Error>();

  const sortInventory = useCallback(
    (a: InventoryItem, b: InventoryItem) => {
      if (!userObject) return sortName(a, b);

      const aFavorite = userObject.favorites?.inventory.includes(a.id);
      const bFavorite = userObject.favorites?.inventory.includes(b.id);

      if (aFavorite && !bFavorite) return -1;
      if (!aFavorite && bFavorite) return 1;

      return sortName(a, b);
    },
    [userObject]
  );

  useSubscribe<InventoryItem>(
    prefixCollection("inventory", tenant),
    setInventory,
    setError,
    sortInventory
  );

  const deleteItem = async (item: InventoryItem) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${item.name}?`
    );
    if (!confirmed) return;
    await deleteInv(prefixCollection("inventory", tenant), item.id);
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: You can add items and set a quantity later"}
          actions={
            <Button
              iconName="add-plus"
              variant="primary"
              onClick={() => navigate("/Inventory/new")}
            >
              Add ingredient
            </Button>
          }
        >
          Inventory
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ErrorBar error={error} setError={setError} />
        <Table
          variant="embedded"
          items={inventory}
          columnDefinitions={[
            {
              header: "",
              cell: (item) => (
                <>
                  <Button
                    iconName={
                      (userObject?.favorites?.inventory ?? []).includes(item.id)
                        ? "heart-filled"
                        : "heart"
                    }
                    variant="inline-link"
                    onClick={() => {
                      if (
                        (userObject?.favorites?.inventory ?? []).includes(
                          item.id
                        )
                      )
                        removeFavoriteFromUser(
                          FavoriteType.INVENTORY,
                          item.id,
                          userObject?.id ?? ""
                        );
                      else
                        addFavoriteToUser(
                          FavoriteType.INVENTORY,
                          item.id,
                          userObject?.id ?? ""
                        );
                    }}
                  />
                </>
              ),
            },
            {
              header: "Name",
              cell: (item) => (
                <Link to={`/Inventory/${item.id}`}>{item.name}</Link>
              ),
              isRowHeader: true,
            },
            {
              header: "Quantity",
              cell: (item) => (
                <>
                  {item.quantity} {item.unit}
                </>
              ),
            },
            {
              header: "Price per unit",
              cell: (item) => (
                <>
                  €{item.price}/{item.unit}
                </>
              ),
            },
            {
              header: "Value",
              cell: (item) => (
                <>€{((item.price ?? 0) * item.quantity).toFixed(2)}</>
              ),
            },
            {
              header: "Actions",
              cell: (item) => (
                <>
                  <Button
                    iconName="remove"
                    variant="inline-link"
                    onClick={() => deleteItem(item)}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </ContentLayout>
  );
};

export default Inventory;
