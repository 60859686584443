import React, { FC } from "react";
import { HydroPiSample } from "../components/modules/HydroPi";
import { SpaceBetween } from "@cloudscape-design/components";
import { toDate } from "../utils/timestamp";

interface TiltsWidgetProps {
  tilts: HydroPiSample[];
}

const TiltsWidget: FC<TiltsWidgetProps> = ({ tilts }) => {
  return (
    <SpaceBetween size="s" direction="vertical">
      {tilts.map((sample) => {
        return (
          <div
            key={sample.id}
            style={{
              width: "100%",
              height: 56,
              position: "relative",
              backgroundColor: sample.color,
              color: sample.color === "white" ? "black" : "white",
              borderRadius: 5,
              border: "1px solid grey",
              overflow: "hidden",
              padding: 5,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 32,
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              {sample.color}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 18,
                position: "absolute",
                top: 5,
                left: 5,
              }}
            >
              {sample.avg_temp_c.toFixed(1)}°C
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 18,
                position: "absolute",
                bottom: 5,
                left: 5,
              }}
            >
              {sample.avg_gravity.toFixed(3)}
            </div>
            {/* <div
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                }}
              >
                {(sample.battery / 0.05).toFixed(0)}%
              </div> */}
            {/* sample age, from timestamp (hours ago) */}
            <div
              style={{
                fontWeight: "bold",
                fontSize: 18,
                position: "absolute",
                top: 5,
                left: "40%",
              }}
            >
              {Math.round(
                (Date.now() - toDate(sample.timestamp).getTime()) / 3600000
              )}{" "}
              h. ago
            </div>
          </div>
        );
      })}
    </SpaceBetween>
  );
};

export default TiltsWidget;
