import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";
import { fetchItem } from "../api/generic";
import { db } from "./firebase";

export type Token = {
    id: string;
    tokens: string[];
  };
  

// Save or update a token in the "tokens" collection
export const saveToken = async (userId: string, token: string): Promise<void> => {
  try {
    const docRef = doc(db, "tokens", userId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Update the document if it exists
      await updateDoc(docRef, {
        tokens: arrayUnion(token),
      });
    } else {
      // Create the document if it doesn't exist
      await setDoc(docRef, { id: userId, tokens: [token] });
    }

    // console.log(`Token for user ${userId} added/updated successfully.`);
  } catch (error) {
    console.error("Error saving token:", error);
    throw new Error("Failed to save token.");
  }
};

export const deleteTokens = async (userId: string): Promise<void> => {
    try {
      const docRef = doc(db, "tokens", userId);
      await setDoc(docRef, { id: userId, tokens: [] });
  
      // console.log(`Tokens for user ${userId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting tokens:", error);
      throw new Error("Failed to delete tokens.");
    }
  }


// Fetch a token for a specific user using the generic fetchItems function
export const fetchToken = async (userId: string): Promise<string[] | null> => {
    try {
      const userToken = await fetchItem<Token>("tokens", userId); // Fetch the token for the user
  
      if (userToken) {
        return userToken.tokens; // Return the token field
      } else {
        return null; // No token found
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      throw new Error("Failed to fetch token.");
    }
  };