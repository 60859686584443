import { SplitPanel } from "@cloudscape-design/components";
import version from "../v.json";
import { useAuthContext } from "../contexts/AuthContext";
import { changes } from "../data/changes";

const ChangesPanel = () => {
  const { userObject } = useAuthContext();

  return (
    <SplitPanel
      header={`${
        userObject?.name ?? "No user"
      } - The Horrible Meadery © 2025 ${
        version.MajorMinorPatch + "." + version.CommitsSinceVersionSource
      }`}
      hidePreferencesButton
    >
      <p>Latest changes:</p>
      <ul>
        {changes.map((change) => (
          <li key={change.title}>
            <strong>{change.title}</strong>
            <br />
            {change.description}
            <br />- <small>({change.note})</small>
          </li>
        ))}
      </ul>
    </SplitPanel>
  );
};

export default ChangesPanel;
