import React, { FC, useEffect, useState } from "react";
import { subscribeToChanges, updateItem } from "../../api/generic";
import { prefixCollection } from "../../utils/prefix";
import { iSpindelSample } from "./HydroPi";
import { useAuthContext } from "../../contexts/AuthContext";
import { Button, FormField, Input, Modal } from "@cloudscape-design/components";

interface IChangeFirstSampleValueProps {
  id?: string;
}

export const ChangeFirstSampleValue: FC<IChangeFirstSampleValueProps> = ({
  id,
}) => {
  const { tenant } = useAuthContext();
  const [show, setShow] = useState(false);
  const [sample, setSample] = useState<iSpindelSample>();
  const [value, setValue] = useState("");
  const [, setError] = useState<Error>();

  const handelUpdate = async () => {
    const newGravity: number = parseFloat(value);

    if (newGravity && id) {
      await updateItem<iSpindelSample>(
        prefixCollection("ispindel", tenant, "-"),
        id,
        { gravity: newGravity }
      );
      setShow(false);
    }
  };

  useEffect(() => {
    const unsubscribe = subscribeToChanges<iSpindelSample>(
      prefixCollection("ispindel", tenant, "-"),
      (res) => setSample(res[0]),
      setError,
      id
    );

    return () => {
      unsubscribe();
    };
  }, [id]);

  return (
    <>
      {sample && <Button onClick={() => setShow(true)}>Set OG</Button>}
      <Modal
        header="Set original gravity"
        visible={show}
        onDismiss={() => setShow(false)}
        footer={<Button onClick={handelUpdate}>Update</Button>}
      >
        <FormField label="Gravity">
          <Input
            value={value}
            onChange={({ detail }) => setValue(detail.value)}
            placeholder={sample?.gravity?.toString() ?? ""}
          />
        </FormField>
      </Modal>
    </>
  );
};
