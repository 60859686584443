import {
  ContentLayout,
  CopyToClipboard,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Toggle,
} from "@cloudscape-design/components";
import { useAuthContext } from "../contexts/AuthContext";
import { useEffect, useState } from "react";
import { UserData } from "../models/UserData";
import Tenant from "../models/Tenant";
import { fetchItemsWithFilter, Filter } from "../api/generic";
import { deleteTokens } from "../config/tokens";
import { useLocalStorage } from "react-use";

const User = () => {
  const { userObject, tenant, handleFCMToken } = useAuthContext();
  const [editedUser, setEditedUser] = useState<UserData | null>(null);
  const [tenantObject, setTenantObject] = useState<Tenant | null>(null);
  const [notificationsEnabled, setNotificationsEnabled] = useLocalStorage(
    "notifications-enabled",
    false
  );

  useEffect(() => {
    const fetchTenant = async () => {
      if (tenant) {
        const filters: Filter<Tenant>[] = [
          { field: "name", value: tenant, operator: "==" },
        ];
        const response = await fetchItemsWithFilter<Tenant>("tenant", filters);
        setTenantObject(response[0]);
      }
    };

    if (tenant) {
      fetchTenant();
    }
  }, [tenant, userObject]);

  useEffect(() => {
    setEditedUser(userObject);
  }, [userObject]);

  const handleEditedUserChange = (field: string, value: any) => {
    setEditedUser((x) => x && { ...x, [field]: value });
  };

  const handleNotificationChange = async (enabled: boolean) => {
    if (enabled) {
      if (Notification.permission === "denied") {
        alert(
          "You have denied notifications. Please enable notifications manually in your browser settings."
        );
        setNotificationsEnabled(false);
        return;
      }

      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        alert(
          "Notifications were not enabled. Please enable them manually in your browser settings if you change your mind."
        );
        setNotificationsEnabled(false);
        return;
      }
    } else {
      const confirmDisable = confirm(
        "Are you sure you want to disable notifications? This will prevent you from receiving important updates. All devices will be affected, and you will need to enable notifications and login to each device again to activate them."
      );
      if (!confirmDisable) return;

      if (userObject?.uid) await deleteTokens(userObject?.uid);
      setNotificationsEnabled(false);
      return;
    }
    if (userObject?.uid) await handleFCMToken(userObject?.uid);
    setNotificationsEnabled(enabled);
  };

  return (
    <ContentLayout
      header={
        <Header
          actions={
            <SpaceBetween size="m">
              <CopyToClipboard
                copyButtonText="Calendar link"
                copyErrorText="Link failed to copy"
                copySuccessText="Link copied"
                textToCopy={`https://generateicalfunction-ogbfqlrosa-ew.a.run.app/?tenant=${tenant}`}
              />
            </SpaceBetween>
          }
          //   description={`Settings for ${userObject?.name}. Here you can see your current settings and also change them.
          //   You can update your profile information, manage your preferences, and configure other account settings.`}
        >
          User settings
        </Header>
      }
    >
      {editedUser && (
        <SpaceBetween size="m">
          <FormField label="UId" description="Your unique identifier">
            <Input value={editedUser?.uid} readOnly />
          </FormField>
          <FormField label="Name" description="Your full name">
            <Input
              value={editedUser?.name}
              onChange={({ detail }) =>
                handleEditedUserChange("name", detail.value)
              }
            />
          </FormField>
          <FormField
            label="Email"
            description="Your email address, linked to your account"
          >
            <Input value={editedUser?.email} readOnly />
          </FormField>
          <FormField label="Role" description="Your role in the application">
            <Input value={editedUser?.role} readOnly />
          </FormField>

          {tenantObject && (
            <FormField
              label="Tenant"
              description="The tenant you are associated with"
            >
              <Input value={tenantObject?.displayName} readOnly />
            </FormField>
          )}

          <FormField label="Notifications">
            <Toggle
              checked={notificationsEnabled || false}
              onChange={({ detail }) =>
                handleNotificationChange(detail.checked)
              }
            />
          </FormField>

          {/* <Button variant="primary" disabled={userObject === editedUser}>
            Save
          </Button> */}
        </SpaceBetween>
      )}
    </ContentLayout>
  );
};

export default User;
